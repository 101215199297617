import React, { useState } from 'react';
import {
  Container,
  List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FenceIcon from '@mui/icons-material/Fence';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import PublishIcon from '@mui/icons-material/Publish';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useAdministrator, useManager, useRestriction,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const location = useLocation();

  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [opencar, setOpencar] = useState(false);

  const handleClickCar = () => {
    setOpencar(!opencar);
  };

  return (
    <Container sx={{ backgroundColor: '#2b3643', height: '100%' }}>
      <>
        <List
          sx={{ backgroundColor: '#2b3643', paddingTop: 4, right: 20 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <MenuItem
            link="/"
            icon={<Tooltip title="MAPA" placement="right"><MapIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
            selected={location.pathname === '/'}
          />
          <MenuItem
            link="/settings/preferences"
            icon={<Tooltip title="CONFIGURAÇÕES" placement="right"><SettingsIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
            selected={location.pathname === '/settings/preferences'}
          />
          <MenuItem
            link="/reports/route"
            icon={<Tooltip title="RELATORIOS" placement="right"><SummarizeIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
            selected={location.pathname === '/reports/route'}
          />
          {!readonly && (
            <>
              <ListItemButton sx={{ right: 15 }} onClick={handleClickCar}>
                <ListItemIcon>
                  {opencar ? <ExpandLess sx={{ color: grey[500], width: 15 }} /> : <ExpandMore sx={{ color: grey[500], width: 15 }} />}
                  <Tooltip title="VEICULOS" placement="right"><CarCrashIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>
                </ListItemIcon>
              </ListItemButton>
              <Collapse in={opencar} timeout="auto" unmountOnExit>
                <List component="div">
                  <MenuItem
                    link="/settings/devices"
                    icon={<Tooltip title="LISTA DE VEICULOS" placement="right"><DirectionsCarIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                    selected={location.pathname.startsWith('/settings/device')}
                  />
                  <MenuItem
                    link="/settings/notifications"
                    icon={<Tooltip title="ALERTAS" placement="right"><NotificationsIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                    selected={location.pathname.startsWith('/settings/notification')}
                  />
                  <MenuItem
                    link="/geofences"
                    icon={<Tooltip title="CERCA" placement="right"><FenceIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                    selected={location.pathname.startsWith('/settings/geofence')}
                  />
                  {!features.disableCalendars && (
                    <MenuItem
                      link="/settings/calendars"
                      icon={<Tooltip title="CALENDARIO" placement="right"><TodayIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                      selected={location.pathname.startsWith('/settings/calendar')}
                    />
                  )}
                  {!features.disableDrivers && (
                    <MenuItem
                      link="/settings/drivers"
                      icon={<Tooltip title="MOTORISTAS" placement="right"><FolderSharedIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                      selected={location.pathname.startsWith('/settings/driver')}
                    />
                  )}
                  {admin && (
                    <MenuItem
                      link="/settings/attributes"
                      icon={<Tooltip title="ATRIBUTOS" placement="right"><StorageIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                      selected={location.pathname.startsWith('/settings/attribute')}
                    />
                  )}
                  {!features.disableMaintenance && (
                    <MenuItem
                      link="/settings/maintenances"
                      icon={<Tooltip title="MANUTENÇÃO" placement="right"><BuildIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                      selected={location.pathname.startsWith('/settings/maintenance')}
                    />
                  )}
                  {admin && (
                    <MenuItem
                      link="/settings/commands"
                      icon={<Tooltip title="COMANDOS" placement="right"><PublishIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                      selected={location.pathname.startsWith('/settings/command')}
                    />
                  )}
                </List>
              </Collapse>
            </>
          )}
        </List>
        {manager && (
          <List
            sx={{ backgroundColor: '#2b3643', right: 20, top: -20 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton sx={{ right: 15 }} onClick={handleClick}>
              <ListItemIcon>
                {open ? <ExpandLess sx={{ color: grey[500], width: 15 }} /> : <ExpandMore sx={{ color: grey[500], width: 15 }} />}
                <Tooltip title="USUARIOS" placement="right"><PersonAddAlt1Icon sx={{ color: grey[500], width: 22 }} /></Tooltip>
              </ListItemIcon>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div">
                <MenuItem
                  link="/settings/users"
                  icon={<Tooltip title="LISTA DE USUARIOS" placement="right"><PeopleIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                  selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
                />
                {!features.disableGroups && (
                  <MenuItem
                    link="/settings/groups"
                    icon={<Tooltip title="GRUPOS" placement="right"><Diversity3Icon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                    selected={location.pathname.startsWith('/settings/group')}
                  />
                )}
                <MenuItem
                  link={`/settings/user/${userId}`}
                  icon={<Tooltip title="MINHA CONTA" placement="right"><PersonIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                  selected={location.pathname === `/settings/user/${userId}`}
                />
              </List>
            </Collapse>
            {admin && (
              <MenuItem
                link="/settings/server"
                icon={<Tooltip title="SERVIDOR" placement="right"><StorageIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
                selected={location.pathname === '/settings/server'}
              />
            )}
          </List>
        )}
      </>
      <List sx={{ backgroundColor: '#2b3643', paddingTop: 4, right: 20 }}>
        <MenuItem
          link="/login"
          icon={<Tooltip title="Sair" placement="right"><ExitToAppIcon sx={{ color: grey[500], width: 22 }} /></Tooltip>}
          selected={location.pathname === '/login'}
        />
      </List>
    </Container>
  );
};

export default SettingsMenu;
