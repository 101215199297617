import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Divider, Typography, Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { prefixString } from '../common/util/stringUtils';
import { formatBoolean } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  header1: {
    display: 'flex',
    margin: 20,
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  header2: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15,
    padding: 10,
  },
}));

const NotificationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const notificationsCount = (users) => items.filter(filterByKeyword(searchKeyword)).map((item) => item.name === users).length;

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/notifications');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const formatList = (prefix, value) => {
    if (value) {
      return value
        .split(/[, ]+/)
        .filter(Boolean)
        .map((it) => t(prefixString(prefix, it)))
        .join(', ');
    }
    return '';
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedNotifications']}>
      <Divider />
      <div className={classes.header1}>
        <Typography sx={{ color: '#2b3643' }} className={classes.text} variant="h6">LISTA DE NOTIFICAÇÕES</Typography>
        <CollectionFab editPath="/settings/notification" />
      </div>
      <div className={classes.header2}>
        <div className={classes.avatar}>
          <Avatar sx={{ width: 50, height: 30, borderRadius: 5, marginLeft: 0.5, marginRight: 1, color: 'white', backgroundColor: 'green' }}>{`${notificationsCount('notifications')}`}</Avatar>
          <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        </div>
      </div>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('notificationType')}</TableCell>
            <TableCell>{t('notificationAlways')}</TableCell>
            <TableCell>{t('sharedAlarms')}</TableCell>
            <TableCell>{t('notificationNotificators')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell>{t(prefixString('event', item.type))}</TableCell>
              <TableCell>{formatBoolean(item.always, t)}</TableCell>
              <TableCell>{formatList('alarm', item.attributes.alarms)}</TableCell>
              <TableCell>{formatList('notificator', item.notificators)}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/notification" endpoint="notifications" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={5} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default NotificationsPage;
