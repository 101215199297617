import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, ListItemText, ListItemButton,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import FastForwardIcon from '@mui/icons-material/FastForward';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar';
import PowerIcon from '@mui/icons-material/Power';
import Key from '@mui/icons-material/KeySharp';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor, formatNumber, formatNumericHours,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import usePersistedState from '../common/util/usePersistedState';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '17px',
    height: '17px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  movit: {
    color: '#2b3643',
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);
  const geofences = useSelector((state) => state.geofences.items);

  const [devicePrimary] = usePersistedState('devicePrimary', 'name');
  const [deviceSecondary] = usePersistedState('deviceSecondary', '');

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };

  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
      </>
    );
  };

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
      >
        <ListItemText
          sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <div style={{ width: 300, alignItems: 'flex-end', flexDirection: 'column', position: 'absolute', paddingTop: 80 }}>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('status') && (
              <Tooltip title={`${formatStatus(item.status, t)}: ${moment(item.lastUpdate).fromNow()}`}>
                <IconButton size="small">
                  {item.status === 'online' || !item.lastUpdate ? (
                    item.status.online || !item.lastUpdate
                      ? (<WifiTetheringIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                      : (<WifiTetheringIcon sx={{ width: '16px', height: '16px' }} className={classes.positive} />)
                  ) : item.status === 'offline' || !item.lastUpdate ? (
                    item.status.offline || !item.lastUpdate
                      ? (<WifiTetheringOffIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                      : (<WifiTetheringOffIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                  ) : (
                    item.status === 'unknown' || !item.lastUpdate
                      ? (<WifiTetheringErrorIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                      : (<WifiTetheringErrorIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <Key sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  ) : (
                    <Key sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('blocked') && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(position.attributes.positionBlocked, t)}`}>
                <IconButton size="small">
                  {position.attributes.blocked ? (
                    <LockOpenIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                  ) : (
                    <LockOpenIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('out1') && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(position.attributes.out1, t)}`}>
                <IconButton size="small">
                  {position.attributes.out1 ? (
                    <LockOpenIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                  ) : (
                    <LockOpenIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('sat') && (
              <Tooltip title={`${t('positionSat')}: ${formatNumber(position.attributes.sat, t)}`}>
                <IconButton size="small">
                  <SatelliteAltIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('odometer') && (
              <Tooltip title={`${t('positionOdometer')}: ${formatNumber(position.attributes.odometer, t)}`}>
                <IconButton size="small">
                  <PinOutlinedIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('rssi') && (
              <Tooltip title={`${t('positionRssi')}: ${formatPercentage(position.attributes.rssi, t)}`}>
                <IconButton size="small">
                  {position.attributes.rssi >= 50 ? (
                    position.attributes.rssi
                      ? (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                      : (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                  ) : position.attributes.rssi > 20 ? (
                    position.attributes.rssi
                      ? (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.medium} />)
                      : (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.medium} />)
                  ) : (
                    position.attributes.rssi
                      ? (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                      : (<SignalCellularConnectedNoInternet4BarIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('charge') && (
              <Tooltip title={`${t('positionCharge')}: ${formatBoolean(position.attributes.charge, t)}`}>
                <IconButton size="small">
                  {position.attributes.charge ? (
                    <PowerIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  ) : (
                    <PowerIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('hours') && (
              <Tooltip title={`${t('positionHours')}: ${formatNumericHours(position.attributes.hours, t)}`}>
                <IconButton size="small">
                  {position.attributes.hours ? (
                    <QueryBuilderIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  ) : (
                    <QueryBuilderIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('motion') && (
              <Tooltip title={`${t('positionMotion')}: ${formatBoolean(position.attributes.motion, t)}`}>
                <IconButton size="small">
                  {position.attributes.motion ? (
                    <FastForwardIcon sx={{ width: '17px', height: '17px' }} className={classes.movit} />
                  ) : (
                    <FastForwardIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <IconButton size="small">
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge
                      ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                      : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge
                      ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                      : (<Battery60Icon fontSize="small" className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                      : (<Battery20Icon fontSize="small" className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('power') && (
              <Tooltip title={`${t('positionPower')}: ${formatNumber(position.attributes.power)}`}>
                <IconButton size="small">
                  {position.attributes.power > 11 ? (
                    position.attributes.charge
                      ? (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                      : (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.positive} />)
                  ) : position.attributes.power > 6 ? (
                    position.attributes.charge
                      ? (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.medium} />)
                      : (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                      : (<ElectricCarIcon sx={{ width: '17px', height: '17px' }} className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
