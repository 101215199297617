import React from 'react';
import { makeStyles } from '@mui/styles';
import LogoNovo from '../resources/images/logo-novo.png';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '340px',
    maxHeight: '220px',
    marginBottom: '40px',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage = () => {
  const classes = useStyles();

  return <img className={classes.image} src={LogoNovo} alt="" />;
};

export default LogoImage;
