import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Divider, Typography, Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  header1: {
    display: 'flex',
    margin: 20,
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  header2: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15,
    padding: 10,
  },
}));

const DriversPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const drivesCount = (drivers) => items.filter(filterByKeyword(searchKeyword)).map((item) => item.name === drivers).length;

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/drivers');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <Divider />
      <div className={classes.header1}>
        <Typography sx={{ color: '#2b3643' }} className={classes.text} variant="h6">LISTA DE MOTORISTAS</Typography>
        <CollectionFab editPath="/settings/driver" />
      </div>
      <div className={classes.header2}>
        <div className={classes.avatar}>
          <Avatar sx={{ width: 50, height: 30, borderRadius: 5, marginLeft: 0.5, marginRight: 1, color: 'white', backgroundColor: 'green' }}>{`${drivesCount('drives')}`}</Avatar>
          <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        </div>
      </div>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('deviceIdentifier')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.uniqueId}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/driver" endpoint="drivers" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={3} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default DriversPage;
