import React from 'react';
import { Fab, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useRestriction } from '../../common/util/permissions';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'sticky',
    width: 120,
    height: 20,
    borderRadius: 18,
    right: theme.spacing(5),
    left: 1500,
    top: -10,
  },
}));

const CollectionFab = ({ editPath, disabled }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const readonly = useRestriction('readonly');

  if (!readonly && !disabled) {
    return (
      <Fab size="medium" sx={{ color: '#fff', backgroundColor: '#2b3643' }} className={classes.fab} onClick={() => navigate(editPath)}>
        <Button variant="text" color="inherit">Adicionar</Button>
      </Fab>
    );
  }
  return '';
};

export default CollectionFab;
