import React from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../common/components/LocalizationProvider';

export const filterByKeyword = (keyword) => (item) => !keyword || JSON.stringify(item).toLowerCase().includes(keyword.toLowerCase());

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'end',
    alignItems: 'end',
  },
}));

const SearchHeader = ({ keyword, setKeyword }) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <div className={classes.header}>
      <TextField
        variant="outlined"
        placeholder={t('sharedSearch')}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </div>
  );
};

export default SearchHeader;
