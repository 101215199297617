import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Divider, Typography, Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { formatDistance, formatSpeed } from '../common/util/formatter';
import { useAttributePreference } from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  header1: {
    display: 'flex',
    margin: 20,
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  header2: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15,
    padding: 10,
  },
}));

const MaintenacesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const speedUnit = useAttributePreference('speedUnit');
  const distanceUnit = useAttributePreference('distanceUnit');

  const maintenancesCount = (users) => items.filter(filterByKeyword(searchKeyword)).map((item) => item.name === users).length;

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/maintenance');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const convertAttribute = (key, value) => {
    const attribute = positionAttributes[key];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return formatSpeed(value, speedUnit, t);
        case 'distance':
          return formatDistance(value, distanceUnit, t);
        default:
          return value;
      }
    }

    return value;
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedMaintenance']}>
      <Divider />
      <div className={classes.header1}>
        <Typography sx={{ color: '#2b3643' }} className={classes.text} variant="h6">LISTA DE MANUTENÇÕES</Typography>
        <CollectionFab editPath="/settings/maintenance" />
      </div>
      <div className={classes.header2}>
        <div className={classes.avatar}>
          <Avatar sx={{ width: 50, height: 30, borderRadius: 5, marginLeft: 0.5, marginRight: 1, color: 'white', backgroundColor: 'green' }}>{`${maintenancesCount('maintenancesCount')}`}</Avatar>
          <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        </div>
      </div>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('sharedType')}</TableCell>
            <TableCell>{t('maintenanceStart')}</TableCell>
            <TableCell>{t('maintenancePeriod')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{convertAttribute(item.type, item.start)}</TableCell>
              <TableCell>{convertAttribute(item.type, item.period)}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/maintenance" endpoint="maintenance" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={5} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default MaintenacesPage;
